<template>
  <v-container
    v-if="$can([titlePerms + '_list'])"
    id="crud"
    fluid
    tag="section"
  >
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title
          >Administración de {{ title }} de {{ $appName }}</v-toolbar-title
        >
      </v-toolbar>

      <v-data-table
        :headers="showHeaders"
        :items="filteredData"
        :search="search"
        sort-by="secret"
        class="elevation-1"
      >
        <template v-slot:[`header.nomenclatura`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:[`header.detalle`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:[`header.activo`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>
        <template v-slot:[`item.clientName`]="{ item }">
          <div
            dark
            class="rounded-pill ml-1 mr-1 text-center"
            :style="$getColorClient(item.clientName)"
          >
            {{ item.clientName }}
          </div>
        </template>
        <template v-slot:top>
          <v-toolbar flat color="white" class="pr-4">
            <v-row>
              <v-col sm="6" class="pt-20">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="'Busqueda de ' + title"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col sm="2"></v-col>
              <v-col sm="3" class="">
                <v-autocomplete
                  v-model="selectedHeaders"
                  :items="headers"
                  label="Columnas Visibles"
                  multiple
                  return-object
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 2">
                      <span>{{ item.text }}</span>
                    </v-chip>
                    <span v-if="index === 2" class="grey--text caption"
                      >(otras {{ selectedHeaders.length - 2 }}+)</span
                    >
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col sm="1">
                <v-dialog v-model="dialog" max-width="50%" persistent>
                  <template
                    v-if="$can([titlePerms + '_create'])"
                    v-slot:activator="{ on, attrs }"
                  >
                    <v-btn
                      :color="$cv('principal')"
                      dark
                      class="mx-2"
                      fab
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon blue>mdi-plus</v-icon></v-btn
                    >
                  </template>
                  <v-card>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-toolbar dark :color="$cv('principal')">
                        <v-btn icon dark @click="dialog = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>

                        <v-toolbar-title>{{
                          editedIndex != -1
                            ? formTitle +
                              ": " +
                              editedItem.name +
                              " (" +
                              editedItem.clientName +
                              ")"
                            : formTitle
                        }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                          <v-btn
                            dark
                            text
                            @click="$save()"
                            v-if="!vista"
                            :disabled="!valid"
                            >Guardar</v-btn
                          >
                        </v-toolbar-items>
                      </v-toolbar>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                              v-if="editedIndex == -1"
                              cols="12"
                              md="12"
                              sm="12"
                            >
                              <v-autocomplete
                                :items="clients"
                                item-value="id"
                                item-text="name"
                                label="Producto"
                                v-model="editedItem.containerId"
                                :rules="[$rulesRequerido]"
                                return-object
                              ></v-autocomplete>
                            </v-col>
                            <v-col v-else>
                              <v-text-field
                                v-model="editedItem.clientName"
                                label="Producto"
                                disabled
                                title="El Producto no se puede editar"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              v-if="editedIndex == -1"
                              cols="12"
                              md="12"
                              sm="12"
                            >
                              <v-text-field
                                v-model="editedItem.name"
                                label="Nombre"
                                :disabled="editedIndex != -1 ? true : false"
                                :rules="[$rulesRequerido, $rulesMax500]"
                                :title="
                                  editedIndex != -1
                                    ? 'El nombre del permiso no se puede editar'
                                    : ''
                                "
                              ></v-text-field>
                            </v-col>
                            <!--
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                v-model="editedItem.name"
                                label="Nombre"
                                :disabled="vista"
                                :rules="[
                                  $rulesRequerido,
                                  $rulesAlfaNum,
                                  $rulesMax500,
                                ]"
                              ></v-text-field>
                            </v-col>
                            -->
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                v-model="editedItem.description"
                                label="Descripción"
                                :disabled="vista"
                                :rules="[
                                  $rulesRequerido,
                                  $rulesAlfaNum,
                                  $rulesMax500,
                                ]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.privs`]="{ item }">
          <v-chip v-for="tag in item.privs" :key="tag.id">
            {{ tag.name }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row class="">
            <v-col cols="6" md="6" sm="6" style="padding: 6px 0px 0px 0px">
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="transparent elevation-0"
                    dark
                    small
                    v-bind="attrs"
                    v-on="on"
                    min-width="30px"
                  >
                    <v-icon
                      large
                      color="blue-grey lighten-3"
                      style="font-size: 30px"
                    >
                      mdi-dialpad
                    </v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-if="$can([titlePerms + '_read'])"
                    @click="
                      vista = true;
                      getPermiso(item);
                    "
                  >
                    <v-list-item-icon class="mr-0"
                      ><v-icon
                        small
                        class="mr-2"
                        :color="$cv('btnVista')"
                        title="Ver"
                      >
                        mdi-eye-outline
                      </v-icon> </v-list-item-icon
                    ><v-list-item-content>
                      <v-list-item-title>Ver</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="$can([titlePerms + '_update'])"
                    @click="
                      vista = false;
                      getPermiso(item);
                    "
                  >
                    <v-list-item-icon class="mr-0"
                      ><v-icon
                        small
                        class="mr-2"
                        :color="$cv('btnEditar')"
                        title="Editar"
                      >
                        mdi-pencil
                      </v-icon> </v-list-item-icon
                    ><v-list-item-content>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="$can([titlePerms + '_delete'])"
                    @click="
                      $deleteItem(item.name + '*' + item.containerId, item.name)
                    "
                  >
                    <v-list-item-icon class="mr-0">
                      <v-icon
                        small
                        class="mr-2"
                        :color="$cv('btnEliminar')"
                        title="Eliminar"
                      >
                        mdi-delete
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Eliminar</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
function title() {
  return "Permisos";
}

export default {
  data: (vm) => ({
    valid: true,
    nowDate: new Date().toISOString().slice(0, 10),
    _method: "PUT",
    autoGrow: true,
    rows: 1,
    title: title(),
    dessertActivo: "",
    route: "permissions",
    titlePerms: title().toLowerCase(),
    menu: false,
    modal: false,
    menu2: false,
    dialog: false,
    snackbar: false,
    visible: true,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    search: "",
    vista: false,
    users: false,
    headers: [
      { text: "Acciones", value: "actions", sortable: false },
      { text: "Producto", filterable: true, value: "clientName" },
      { text: "Permiso", filterable: true, value: "name" },
      { text: "Detalle", filterable: true, value: "description" },
    ],

    desserts: [],
    clients: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      description: "",
      containerId: "",
      clientId: "",
      clientName: "",
      id: "",
    },
    defaultItem: {
      name: "",
      description: "",
      containerId: "",
      clientId: "",
      clientName: "",
      id: "",
    },
    filters: {
      nomenclatura: "",
      detalle: "",
      activo: "",
    },
    filterKey: [],
    selectedHeaders: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Registrar Permiso"
        : this.vista == false
        ? "Editar Permiso"
        : "Permiso";
    },
    filteredData() {
      return this.$filteredData().data !== undefined
        ? this.$filteredData().data
        : this.$filteredData();
    },
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
  },

  watch: {
    dialog(val) {
      val || this.$close();
    },
  },

  created() {
    this.$initialize();
    this.$getListForSelect("clients");
    this.selectedHeaders = this.headers;
  },

  methods: {
    getPermiso(permiso) {
      this.editedIndex = permiso.name;
      this.editedItem = Object.assign({}, permiso);
      this.dialog = true;
    },
    filterByNomenclatura(item) {
      return this.$filterBy(item, "nomenclatura");
    },
    filterByDetalle(item) {
      return this.$filterBy(item, "detalle");
    },
    filterByActivo(item) {
      return this.$filterBy(item, "activo");
    },
  },
  mounted() {
    console.log("Componente " + this.title + " creado");
  },
};
</script>
